import React, { useEffect } from "react"
import * as GRADIENT from "../../utils/gradient"

const Gradient = () => {
  useEffect(() => GRADIENT.draw())

  return (
    <canvas
      css={`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 5px);
        z-index: 0;
        padding: 0;
        margin: 0;
      `}
    />
  )
}

export default Gradient
