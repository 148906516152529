export function draw() {
  const docHeight = document.body.clientHeight
  let c = document.querySelector("canvas")
  var ctx = c.getContext("2d")
  c.height = docHeight

  // calc colors
  let deviation = 33

  let r = Math.floor(Math.random() * deviation + (200 - deviation))
  let g = Math.floor(Math.random() * deviation + (119 - deviation))
  let b = Math.floor(Math.random() * deviation + (88 - deviation))

  let genColor = `rgb(${r}, ${g}, ${b})`

  // Create gradient
  var grd = ctx.createLinearGradient(0, 0, 0, docHeight)
  grd.addColorStop(0, "black")
  grd.addColorStop(0.35, "#0d0e1c")
  grd.addColorStop(0.75, "#60697F")
  grd.addColorStop(1, genColor)

  // Fill with gradient
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, window.innerWidth, docHeight)
}
