import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { animated } from "react-spring"
import styled from "styled-components"
import { bpMobile } from "../../utils/vars"
import RichText from "./RichText"

const Wrapper = styled(animated.div)`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 7;
  box-sizing: border-box;
  padding: 8rem 3.5rem;

  a {
    font-style: italic;
  }

  @media only screen and (max-width: ${bpMobile}) {
    padding: 5rem 1rem 8rem 1rem;
  }

  /* backdrop blur filter */
  @supports (-webkit-backdrop-filter: blur(180px) saturate(150%)) or
    (backdrop-filter: blur(180px) saturate(150%)) {
    backdrop-filter: blur(180px) saturate(150%);
    -webkit-backdrop-filter: blur(180px) saturate(150%);
    background: none;
  }
  background: black; // fallback
`

const AboutModal = ({ active }) => {
  const {
    contentfulMiscellaneousAboutTextRichTextNode,
  } = useStaticQuery(graphql`
    query About {
      contentfulMiscellaneousAboutTextRichTextNode {
        json
      }
    }
  `)

  const { json } = contentfulMiscellaneousAboutTextRichTextNode

  if (active) {
    return (
      <Wrapper>
        <RichText text={json} />
      </Wrapper>
    )
  } else {
    return null
  }
}

export default AboutModal
