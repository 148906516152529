// Renders text from contentfuls rich text editor

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import React from "react"

const RichText = ({ text }) => {
  const Bold = ({ children }) => <span css="font-weight: 900">{children}</span>
  const Text = ({ children }) => <p>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  return <>{documentToReactComponents(text, options)}</>
}

export default RichText
