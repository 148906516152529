import React from "react"
import "reset-css"
import { createGlobalStyle } from "styled-components"
import Canvas from "../components/Scenery/Canvas"
import SEO from "../components/seo"
import { Yellow } from "../utils/vars"
import "./fonts.css"
import { ParallaxProvider } from "react-scroll-parallax"

const GlobalStyle = createGlobalStyle`
::selection {
  color: black;
  background: ${Yellow};
}

html {
  background: black;
}

body {
  font-family: 'Old Standard TT', serif;
  text-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: ${Yellow};
  position: relative;
  background: black;
}

span {  
  font-size: 2rem;
}

a {
  text-decoration: none;
  color: ${Yellow};
}

a:hover, .pseudo-link:hover {
  color: ${Yellow};
  opacity: 0.8;
  font-style: italic;
}

p {
  font-size: 1.5rem;
  line-height: 1.15;
  max-width: 60ch;
  margin-bottom: 2.5rem;
}

@media (max-width: 576px) {  
  span {  
    font-size: 1.5rem;
  }

  p {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {  }
`

const Layout = ({ children }) => (
  <ParallaxProvider scrollAxis="vertical">
    <SEO title="LUC KARSTEN" />
    <GlobalStyle />

    <Canvas />
    {children}
  </ParallaxProvider>
)

export default Layout
