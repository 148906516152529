import React, { useEffect, useRef, useState } from "react"
import { animated, config, useSpring } from "react-spring"
import styled from "styled-components"
import * as GRADIENT from "../utils/gradient"
import { bpMobile } from "../utils/vars"
import AboutModal from "./About/AboutModal"

const Wrapper = styled(animated.nav)`
  user-select: none;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  position: fixed;
  box-sizing: border-box;
  padding: 2rem 3.5rem;
  box-sizing: border-box;

  @media only screen and (max-width: ${bpMobile}) {
    padding: 1rem;
  }
`

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const About = styled.span`
  margin-right: 2rem;
  cursor: pointer;

  @media only screen and (max-width: ${bpMobile}) {
    margin-right: 1.25rem;
  }
`

const Nav = () => {
  // The "About" section's state
  const [active, setActive] = useState(false)

  // If the "about" overlay is scrolled, fade away the nav to prevent fonts intermingling
  const [scrolled, setScrolled] = useState(false)
  const ref = useRef(null)
  const fade = useSpring({
    opacity: scrolled ? 0 : 1,
    transform: scrolled ? "translateY(-50px)" : "translateY(0px)",
    config: config.stiff,
  })

  function handleScroll(e) {
    if (e.target.scrollTop > 0) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    if (ref.current) {
      if (ref.current.children.length !== 0) {
        const parent = ref.current.children[0]
        parent.addEventListener("scroll", e => handleScroll(e))
        return () => parent.removeEventListener("scroll", e => handleScroll(e))
      }
    }
  })

  // scroll to bottom when logo is clicked and trigger a background refresh
  const handleClick = () => {
    const docHeight = document.body.clientHeight
    if (window.innerWidth >= 600) {
      window.scrollTo({
        top: docHeight,
        behavior: "smooth",
      })
      GRADIENT.draw()
    }
  }

  return (
    <>
      <Wrapper style={fade}>
        <InnerWrapper>
          <span
            css="cursor: pointer"
            id="logo"
            onClick={() => handleClick()}
            onKeyDown={() => handleClick()}
          >
            Luc Karsten
          </span>
          <div id="right" css="display: flex">
            <About onClick={() => setActive(!active)} className="pseudo-link">
              {active ? "Home" : "About"}
            </About>
            <span>
              <a href="mailto:info@luckarsten.com">Contact</a>
            </span>
          </div>
        </InnerWrapper>
      </Wrapper>

      <section
        ref={ref}
        onClick={() => setActive(false)}
        onKeyDown={() => setActive(false)}
      >
        <AboutModal active={active} />
      </section>
    </>
  )
}

export default Nav
